<template>
    <div class="container_site">
        <div class="ubication_dimension">
            <p class="ubication_dimension--home" @click="$router.push({name: 'Home'})" style="color: #FFFFFF">Inicio</p>
            <img src="@/assets/img/arrow-breadcrumb.svg" class="ubication_dimension--breadcrumb">
            <p class="ubication_dimension--name_dimension" style="color: #FFFFFF">Contáctanos</p>
        </div>
        <div class="container_contact">
            <div class="container_contact__header">
                <div class="container_contact__header--title">
                    Contáctanos
                </div>
                <!--<div class="container_contact__header--description">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti nostrum quae accusantium vero deserunt ratione error numquam aliquid sed consequatur, reprehenderit, rerum a aliquam obcaecati iusto modi? Repudiandae, natus voluptatum.
                </div>-->
            </div>
            <div class="container_contact__form">
                <b-row class="container_contact__form--item">
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="container_contact__form--item_input">
                        <label for="text-names">Nombres</label>
                        <b-form-input type="text" id="text-names" v-model="contact.first_name"></b-form-input>
                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="container_contact__form--item_input">
                        <label for="text-last_names">Apellidos</label>
                        <b-form-input type="text" id="text-last_names" v-model="contact.last_name"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="container_contact__form--item">
                    <b-col class="container_contact__form--item_input">
                        <label for="text-email">Correo electrónico</label>
                        <b-form-input type="text" id="text-email" v-model="contact.guest_email"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="container_contact__form--item">
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="container_contact__form--item_input">
                        <label for="text-company">Empresa</label>
                        <b-form-input type="text" id="text-company" v-model="contact.company"></b-form-input>
                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="container_contact__form--item_input">
                        <label for="text-phone">Celular</label>
                        <b-form-input type="text" id="text-phone" v-model="contact.phone"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="container_contact__form--item">
                    <b-col class="container_contact__form--item_input">
                        <label for="text-message">Mensaje</label>
                        <b-form-textarea
                            id="text-message"
                            rows="8"
                            v-model="contact.message"
                        ></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button 
                            block 
                            variant="primary" 
                            :style="{ 'background-color': getClient().primary_color, 'borderColor': getClient().primary_color }" 
                            @click="sendMessage()"
                        >
                            <label>Enviar mensaje</label>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return{
            contact:{
                first_name:'',
                last_name:'',
                guest_email:'',
                company:'',
                phone:'',
                message:'',
            }
        }
    },
    methods:{
        sendMessage(){
            console.log('el contact',this.contact)

            if( this.contact.guest_email =='' || this.contact.guest_email == null){
                alert('por favor ingresar un correo para envio')
            }else{
                this.$http.post('townhalls/contact/'+this.getClient().id,{
                    data:this.contact                    
                }).catch(error => {
                    console.log('Error al consultar Contact', error)
                })

                alert('Mensaje Enviado')
                this.contact.first_name=''
                this.contact.last_name=''
                this.contact.guest_email=''
                this.contact.company=''
                this.contact.phone=''
                this.contact.message=''
            }
            
        }
    }
}
</script>
